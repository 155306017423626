import React from 'react'

export const PrestashopIcon = () => {
  return (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <title>Prestashop</title>
      <g fillRule="nonzero" fill="none">
        <circle fill="#D0D0D0" cx="20" cy="20" r="20" />
        <path
          d="M33.08 35.13a20.043 20.043 0 0 1-4.787 3.074A19.92 19.92 0 0 1 20 40a19.918 19.918 0 0 1-13.08-4.87A17.842 17.842 0 0 1 20 29.448c5.163 0 9.815 2.186 13.08 5.684z"
          fill="#535353"
        />
        <path
          d="M30.039 37.301A19.91 19.91 0 0 1 20 40a19.909 19.909 0 0 1-10.037-2.7c2.732-2.533 6.228-4.053 10.037-4.053 3.81 0 7.307 1.52 10.039 4.054z"
          fill="#000"
        />
        <g fill="#B1B1B1">
          <path d="M15.6 30.023h-2.267l-1.8 5.638H13.8zM24.4 30.023h2.267l1.8 5.638H26.2z" />
        </g>
        <path
          d="M34.099 20.133c0 .052 0 .104-.002.156a14.025 14.025 0 0 1-1.452 6.09 14.166 14.166 0 0 1-3.838 4.776 14.057 14.057 0 0 1-8.83 3.101c-6.012 0-11.145-3.756-13.182-9.05a14.055 14.055 0 0 1-.94-5.073c0-7.798 6.322-14.121 14.122-14.121 5.274 0 9.874 2.892 12.298 7.177a14.094 14.094 0 0 1 1.824 6.944z"
          fill="#535353"
        />
        <g fill="#FFF">
          <path d="M29.067 30.779l-.26.376a14.057 14.057 0 0 1-8.83 3.101c-6.012 0-11.145-3.756-13.182-9.05a12.131 12.131 0 0 1 1.404-4.917 12.223 12.223 0 0 1 10.792-6.477c1.99 0 3.906.73 5.698 1.87l.732 1.585.756 1.956 2.89 11.556zM34.099 20.133c0 .052 0 .104-.002.156a14.025 14.025 0 0 1-1.452 6.09l-2.134-1.423-2.791-4.667-1.176-1.966c.3-2.367 2.167-4.567 4.033-4.567.1 0 .2.01.3.028.476.086.955.372 1.412.77.403.351.787.79 1.136 1.26.438 1.362.674 2.813.674 4.32z" />
        </g>
        <path
          d="M26.677 17.656s.3-5.3 3.167-5.667c2.867-.366 3.833 6.267 3.833 6.267l-7-.6z"
          fill="#000"
        />
        <path
          d="M32.29 14.555v5.734h-4.57l-1.176-1.966c.3-2.367 2.167-4.567 4.033-4.567.572 0 1.159.316 1.712.799z"
          fill="#BEBEBE"
        />
        <path
          d="M26.49 32.667c4.418-.615 8.798-5.267 8.798-5.267-1.667-2.345-4.844-7.203-8.819-10.43h-.001c-.357-.29-.721-.567-1.09-.827-.227-.16-.458-.314-.689-.46-.028 1.268-.1 3.117-.273 4.606l-.016.134c-.23 1.87-1.843 5.148-2.67 7.02v.001c-.293.663-.486 1.15-.486 1.335 0 .71-.4 3.954 4.31 3.954.06 0 .12 0 .18-.002.251-.007.505-.028.757-.064"
          fill="#B8B8B8"
        />
        <path
          d="M24.69 15.683c-2.48-4.894-7.009-4.617-8.98-3.894-2.633.967-4.7 3.467-4.533 5.734"
          fill="#000"
        />
        <path
          d="M20.933 14.04c1.299.299 2.555.879 3.756 1.643a67.667 67.667 0 0 1-.082 2.22 39.046 39.046 0 0 1-.191 2.386H8.199a12.223 12.223 0 0 1 10.792-6.477c.656 0 1.305.08 1.942.228"
          fill="#BEBEBE"
        />
        <path
          d="M22.577 28.29c-1.955-.4-5.777-2.756-5.777-2.756s2.666 3.6 6.266 4.622l-.489-1.867z"
          fill="#000"
        />
        <path
          d="M30.267 25.933l-3.776 6.734-.758.064-.178.002c-4.711 0-4.311-3.244-4.311-3.954 0-.186.193-.673.487-1.335v-.002c.758-1.673 5.37-5.038 5.553-8.562.183-3.524 2.983 7.053 2.983 7.053z"
          fill="#000"
        />
        <path
          d="M35.288 27.4l-.001.001-.002.003c-.134.14-4.446 4.657-8.794 5.263-1.559-1.198-2.078-2.338-1.803-3.759.18-.928.699-1.977 1.49-3.241 2-3.2 1.643-6.4.29-8.696h.001c3.975 3.226 7.152 8.084 8.819 10.429z"
          fill="#383838"
        />
        <path
          d="M35.287 27.401l-.002.003c-.134.14-4.446 4.657-8.794 5.263-1.559-1.198-2.078-2.338-1.803-3.759h.001c6.911 1.004 10.504-1.443 10.598-1.507z"
          fill="#3C3C3C"
        />
        <g fill="#DFDFDF">
          <path d="M24.69 15.683a67.684 67.684 0 0 1-.083 2.22 38.989 38.989 0 0 1-.191 2.386h-3.31l-.173-1.91V14.04c1.299.299 2.555.879 3.756 1.643zM34.099 20.133c0 .052 0 .104-.002.156h-3.22v-6.505c.476.087.955.372 1.412.77.403.351.787.79 1.136 1.26.438 1.362.674 2.813.674 4.32z" />
        </g>
        <path
          fill="#939393"
          d="M22.489 19.89l-1.6-5.023-1.24 4.006-5.16 1.416 6.888 1.467z"
        />
        <circle fill="#000" cx="20.933" cy="20.289" r="1.911" />
        <path fill="#939393" d="M32.29 16.623l-.746 3.277 1.494.023z" />
        <ellipse fill="#000" cx="32.29" cy="20.289" rx="1" ry="1.395" />
        <ellipse fill="#FFF" cx="32.29" cy="19.568" rx="1" ry="1" />
        <circle fill="#DFDFDF" cx="20.933" cy="19.356" r="1" />
        <path
          d="M28.752 35.661v2.327A19.912 19.912 0 0 1 20 40c-3.14 0-6.11-.724-8.753-2.013V35.66h17.505z"
          fill="#B1B1B1"
        />
        <path
          d="M28.752 35.661v2.327A19.912 19.912 0 0 1 20 40v-4.339h8.752z"
          fill="#989898"
        />
        <path
          d="M29.457 37.625c-.38.206-.768.399-1.164.579l-.169-2.181H11.876l-.17 2.18c-.394-.18-.782-.371-1.163-.576l.224-2.538h18.466l.224 2.536z"
          fill="#777"
        />
      </g>
    </svg>
  )
}
